import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hi! I'm Dane`}</h1>
    <h2>{`Christian, Husband, Father and Software Engineer`}</h2>
    <p>{`I'm a software engineer from Ontario Canada. I was born in 1989 and grew up at `}<a parentName="p" {...{
        "href": "https://teenranch.com"
      }}>{`Teen Ranch`}</a>{`, a Christian Summer Camp where I had countless opportunities to work in software engineering and information technology.`}</p>
    <p>{`I am self-employed and design and build software solutions for `}<a parentName="p" {...{
        "href": "https://teenranch.com"
      }}>{`Teen Ranch`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.napacanada.com"
      }}>{`NAPA Auto Parts`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.toyotires.com"
      }}>{`TOYO Tires`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.skf.com"
      }}>{`SKF`}</a>{` and many other automotive companies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      